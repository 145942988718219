.loginContent {
  display: flex;
  align-items: center;
  justify-content: center;
  :global {
    .oldVersion {
      width: 80px;
      height: 26px;
      margin-right: 20px;
      font-weight: 400;
      font-size: 14px;
      font-family: 'PingFangSC-Regular, PingFang SC';
      // color: #030A1A;
      line-height: 24px;
      text-align: center;
      border: 1px solid #dcdfe5;
      border-radius: 13px;
      cursor: pointer;
      user-select: none;
  
      &:hover {
        color: #030a1a;
        background-color: #e4e4eb;
      }
    }
  }
}

.login {
  width: 28px;
  height: 20px;
  margin: 0 20px;
  font-weight: 400;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, Microsoft YaHei;
  // color: #030A1A;
  line-height: 20px;
  letter-spacing: 0;
  cursor: pointer;

  &:hover {
    color: #4877ff !important;
  }
}

.freeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 32px;
  margin-left: 30px;
  color: #fff;
  font-size: 14px;
  background: #4877ff;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: #4877ff;
  }
}

.registerOrDashBoardBtn {
  min-width: 82px;
  height: 32px;
  padding: 0 12px;
  color: #fff !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  background: #1a66ff;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
}


.divider {
  color: #dcdfe5 !important;
}

