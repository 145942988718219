.document-popover-wrapper {
  display: flex;
  justify-content: center;
  min-width: 1200px;
  background-color: #fff;
  .document-popover-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    .tools {
      position: relative;
      display: flex;
      background-color: #fff;
    }

    .contactWrap {
      text-align: right;

      .contact {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        width: 360px;
        height: 56px;
        padding-right: 20px;
        color: #030a1a;
        background: #f2f5fa;
      }
    }
  }

  .support {
    .support_item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .desc {
        margin-left: 8px;
        color: #030a1a;
        line-height: 20px;
        font-size: 13px;
      }
    }
  }
}