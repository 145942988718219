.footer_wrapper {
  display: flex;
  justify-content: center;
  padding: 72px 30px 30px;
  background: #f2f5fa;
  .footer_content {
    align-items: center;
    justify-content: space-between;
    width: 1180px;
    min-width: 1180px;
    .footer_top,
    .footer_center,
    .footer_bottom {
      position: relative;
      display: flex;
    }
    .footer_top {
      margin-bottom: 60px;
      .desc_title {
        margin-bottom: 30px;
        color: #030a1a;
        font-weight: bold;
      }
      .desc_container {
        color: #858c99;
        font-size: 12px;
        cursor: pointer;

        &.multi-col {
          display: flex;
          .multi-col-item:not(:first-child) {
            margin-left: 80px;
          }
        }

        .link_item {
          margin-bottom: 15px;
          white-space: nowrap;
          a {
            display: block;
            color: #858c99;
          }
          &:hover a {
            color: #1a66ff;
          }
        }
      }
    }
    .foot_center {
      color: #858c99;
      font-size: 12px;
      // cursor: pointer;
      .iconphone {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .phone {
        color: #030a1a;
        font-size: 14px;
        line-height: 22px;
      }
      .working-time {
        margin-left: 20px;
        line-height: 22px;
      }
    }
    .left {
      position: relative;
      width: 570px;
      img {
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
    .footer_logo {
      width: 186px;
      height: 100px;
    }
  }
  .company-info {
    color: #858c99;
    font-weight: 400;
    font-size: 12px;
    a {
      color: #858c99;
    }
    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .company-certificate {
    position: absolute;
    right: 0;
    bottom: -10px;
  }
  .leftFontBox {
    width: 182px;
    height: 70px;
    margin-top: 13px;
    padding: 12px 20px;
    background: #dcdfe5;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.7;
  }
  .leftBtm {
    position: absolute;
    top: 77px;
    left: 20px;
    cursor: pointer;
    p {
      margin: 0;
    }
  }
  .leftTitle {
    width: 142px;
    height: 22px;
    margin-bottom: 4px;
    color: #030a1a;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    opacity: 1 !important;

    &::after {
      display: inline-block;
      padding-left: 99%;
      content: '';
    }
  }
  .leftContent {
    width: 142px;
    height: 20px;
    color: #858c99;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;

    &::after {
      display: inline-block;
      padding-left: 99%;
      content: '';
    }
  }
  .linkText {
    height: 20px;
    color: #858c99;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  .linkText:hover {
    color: #1a66ff;
  }
}

.footerToolTip {
  max-width: 100% !important;
  :global {
    .ant-tooltip-arrow-content {
      background-color: #fff;
    }
  }
}
.footer-dark {
  background: #030a1a;
  .desc_title,
  .phone,
  .leftTitle {
    color: #fff !important;
  }
  .leftFontBox {
    opacity: 0.2 !important;
  }
  :global {
    .ant-divider {
      opacity: 0.3;
    }
  }
}
