.navBox {
    .navLeft {
        .leftContent {
            .product {
                display: flex;
                align-items: center;
                font-size: 13px;
                line-height: 18px;
                cursor: pointer;
                margin-bottom: 12px;
                .icon_arrow {
                    background-image: url('/icons/navbar_arrow.svg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    transform: translateY(1px);
                    margin-left: 4px;
                }
                span {
                    color: #030a1a !important;
                }
                &:hover {
                    span {
                        color: #1a66ff !important;
                        font-weight: bold;
                    }
                    .icon_arrow {
                        background-image: url("/icons/navbar_arrow_hover.svg");
                    }
                }
            }
        }
    }
    .navContent {
        padding-bottom: 30px !important;
        .popoverTitle {
            width: 96px;
            height: 16px;
            color: #858c99;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
        }
        .productRow {
            justify-content: space-between;
            flex-wrap: nowrap;
            flex-direction: column;
            width: 264px + 18px + 10px;

            & > div {
                width: 100%;
            }
        }
        .productList1 {
            flex-shrink: 0;
            .popoverLinkBox {
                margin-left: -18px;
            }
        }
        .productList2 {
            flex-shrink: 0;
            .popoverLinkBox {
                margin-left: -18px;
            }
        }
        .productList3 {
            width: 264px + 18px + 10px;
            flex-shrink: 0;
            .popoverLinkBox {
                margin-left: -18px;
            }
        }
        .productList4 {
            width: 264px;
            height: 32px;
            padding: 8px;
            background: #E9F0FF;
            margin-top: 8px;
            .productBox {
                width: 264px;
                .productText {
                    width: 200px;
                    height: 15px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #858C99;
                    line-height: 15px;
                    .hrefBtn {
                        color: #1A66FF;
                        padding: 4px;
                    }
                    .hrefBtn:hover {
                        color: #4287ff;
                        cursor: pointer;
                    }
                }
            }
        }
        .popoverLinkBox {
            min-height: 68px;
            padding: 12px 10px 12px 13px;
            border-left: 5px solid transparent;
            cursor: pointer;
            .tagWrapperLine {
                display: flex;
                align-items: center;
            }
        }
        .popoverLinkBox:hover {
            background: rgb(245 247 255);
            border-left: 5px solid #1a66ff;
        }
        .popoverLinkText {
            height: 24px;
            color: #030a1a;
            font-weight: bold;
            font-size: 15px;
            line-height: 24px;
            white-space: pre;
            display: inline-block;
        }
        .popoverDesc {
            min-height: 20px;
            color: #858c99;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .popoverDesc2 {
            display: -webkit-box;          /* 对于旧版 WebKit 浏览器 */
            -webkit-box-orient: vertical;  /* 对于旧版 WebKit 浏览器 */
            -webkit-line-clamp: 2;         /* 对于旧版 WebKit 浏览器 */
            overflow: hidden;
            text-overflow: ellipsis;
            line-clamp: 2;                 /* 对于标准浏览器 */
            min-height: 20px;
            color: #858c99;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            white-space: pre-wrap;
        }
         :global {
            .ant-divider {
                border-color: #DCDFE5;
            }
        }
    }
}