.card {
  display: flex;
  align-items: center;
  width: 360px;
  margin-bottom: 12px;
  padding: 8px 0 8px 8px;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(3, 10, 26, 0.1);
  cursor: pointer;
  transition: all 0.2s;

  .title {
    margin-left: 16px;
    color: #030a1a;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
  }
  &:hover {
    box-shadow: 0 1px 4px 0 rgba(3, 10, 26, 0.2);
    .title {
      color: #1a66ff;
    }
  }
}
