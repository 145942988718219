$white-color: #fff;
$primary-color: #1a66ff;

.userInfoContainer {
  position: relative;
  float: right;
  cursor: pointer;

  .userInfoName {
    display: flex;
    align-items: center;
    color: #3a415c;
    font-size: 14px;
    text-align: right;

    span {
      display: inline-block;
      font-size: 14px;
    }

    &:hover {
      :global {
        .headerUserAvatar {
          background-image: url('/icons/AvatarActive.png');
        }
      }
    }
  }

  .exit {
    cursor: pointer;
  }
}

.textin_user_info_popver {

  :global {
    .user-info-popver {
      width: 240px;
      .ant-menu-inline,
      .ant-menu-vertical,
      .ant-menu-vertical-left {
        border: none;
      }
      .user-info-finance {
        position: relative;
        height: 80px;
        padding: 16px;
        overflow: hidden;
        color: #fff;
        font-size: 14px;
        background: #1a66ff;
        &::before {
          position: absolute;
          top: -8px;
          right: 40px;
          width: 280px;
          height: 240px;
          background-color: rgba(255, 255, 255, 0.08);
          border-radius: 50%;
          content: '';
        }
        .user-info-space {
          display: flex;
          align-items: center;
        }
        .user-info-amount {
          color: #fff;
          flex: 1;
        }
        .charge {
          height: 28px;
          font-size: 14px;
          background: #fff;
          border: none;
          border-radius: 2px;
          transition: all 0.3s;

          span {
            color: $primary-color;
          }

          &:hover {
            opacity: 0.8;
          }
        }
  
        .money {
          color: #fff;
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
  
          .unit {
            color: #fff;
            font-weight: normal;
            font-size: 14px;
            opacity: 0.4;
          }
        }
      }
      .logout-item {
        display: flex;
        align-items: center;
        width: 240px;
        height: 44px;
        padding-left: 12px;
        color: #858c99;
        font-size: 12px;
        background: #fff;
        border-radius: 0 0 4px 4px;
        cursor: pointer;
        &:hover {
          color: $primary-color;
        }
      }
      .user-info-menu {
        position: relative;
        z-index: 2;
        padding-top: 8px;
        cursor: pointer;
        .info-item {
          display: flex;
          align-items: flex-start;
          height: auto;
          margin-top: 0;
          margin-bottom: 0 !important;
          padding: 6px 12px;
          color: #030a1a;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;
          &:hover {
            background: #f2f5fa;
          }
  
          img {
            width: 20px;
            height: 20px;
          }

          .ant-menu-title-content {
            margin-left: 8px;
            color: #030a1a;
          }
        }
  
        .info-item-active {
          color: $primary-color;
          background-color: #f6f8ff;
        }
      }
    }
  
    .textin-user-info-popver {
      width: 240px;
      padding-top: 0 !important;
    }
  
    .ant-popover-arrow {
      display: none !important;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }
}