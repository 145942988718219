.experience-popover-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  margin: 0 auto;
  background-color: #fff;

  .support_wrapper {
    position: relative;
    flex: none !important;
    flex-direction: column;
    flex-grow: 500 !important;
    align-items: flex-end;
    justify-content: flex-start !important;
    min-width: 210px;
    padding-bottom: 40 !important;
    background-origin: content-box;
  }
  .moreProducts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 40px;
    margin-top: auto;
    background: linear-gradient(138deg, #e5eaf2 0%, #f5f8fc 100%);
    box-shadow: 0 4px 12px 0 rgba(6, 6, 26, 0.1), 0 0 20px -15px rgba(19, 18, 60, 0.14);
    cursor: pointer;
    .content {
      display: flex;
      align-items: center;
      width: 210px;
      height: 100%;
      margin: 0 30px;
      column-gap: 8px;
      .text {
        color: #1a66ff;
      }
    }
  }
  .support {
    .serviceSelect {
      margin-top: 8px;
      margin-bottom: 40px;
      .bottomLine {
        max-width: 100%;
      }
      :global {
        .ant-select {
          width: 100%;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: transparent;
        }
      }
    }

    .support_item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .desc {
        margin-left: 8px;
        color: #030a1a;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .experience-popover-content {
    display: flex;
    flex-grow: 1420 !important;
    justify-content: center;
    padding-bottom: 20px !important;

    .tools {
      background-color: #fff;

      .toolsBottom::-webkit-scrollbar {
        display: none;
      }

      .toolsBottom {
        position: relative;
        display: flex;
        height: 640px;
        max-height: min(640px, calc(100vh - 58px - 24px - 60px));

        /* Firefox */
        overflow-y: auto;
        -ms-overflow-style: none;

        /* IE 10 */
        scrollbar-width: none;

        .tagBox {
          display: none !important;
          // display: inline-block !important;
          width: 58px !important;
          height: 18px;
          margin-left: 6px;
          padding: 0 4px;
          color: #e55245;
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
          text-align: center;
          background: rgba(229, 82, 69, 0.06);
          border: 1px solid #e55245cc;
          border-radius: 2px;
          transform: scale(0.9);
        }
      }
    }
  }

  .divider {
    height: 1px;
    margin: 12px 0 8px;
    background-color: #dcdfe5;
  }

  .tools + .tools {
    margin-left: 76px;
  }
}

@media only screen and (max-width: 1300px) {
  .experience-popover-wrapper {
    .experience-popover-content {
      width: auto !important;
    }
  }
}
